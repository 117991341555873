<template>
  <div id="app">
		<div class="top" v-if="top">
			<div class="home" @click="toHome"><img src="../src/assets/img/home.png" alt=""></div>
			<div class="back" @click="toBack"><img src="../src/assets/img/back.png" alt=""></div>
		</div>
		<keep-alive>
      <router-view class="md-wrap" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view class="md-wrap" v-if="!$route.meta.keepAlive" />
		<div v-if="copyright" class="copyright"><a href="https://beian.miit.gov.cn/#/home">沪ICP备13026779号-18&nbsp;&nbsp;&nbsp;&nbsp;</a><img src="./assets/img/gongAn.png">&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009903">沪公网安备31010402009903号</a></div>
  </div>
</template>

<script>
// import { getOpenid } from './common/api/index'
export default {
  data() {
    return {
			top: true,
			copyright: true
    }
  },
  computed: {
  },
	watch: {
		$route () {
			// console.log(this.$route.query)
			if (this.$route.path === '/' || this.$route.query.from === 'home' || this.$route.path === '/clear') {
				this.top = false
			} else {
				this.top = true
			}

			if (this.$route.path === '/disease') {
				this.copyright = false
			}
		}
	},
  created () {
		// let baseURL = this.$store.state.baseURL
		// console.log(baseURL)
		// let that = this
		// // 'is_debug': 1
		// getOpenid({}).then(res => {
		// 	// console.log(res.data)
		// 	that.$store.commit('change', res.data.openid)
		// 	that.$store.commit('changeConsent', res.data.consent)
		// 	if (!res.data.openid && !res.data.consent && that.$route.path != '/'&& that.$route.path != '/clear') {
		// 		that.$router.replace('/')
		// 	} else {
		// 		// if (that.$route.path != '/agreement') {
		// 		// 	that.$router.replace('/agreement?from=home')
		// 		// }
		// 	}
		// })
  },
  mounted () {
  },
  methods: {
		/**
		 * 回到首页
		 */
		toHome () {
			this.$router.push('/')
		},

		/**
		 * 返回上一页
		 */
		toBack () {
			this.$router.go(-1)
		},

		/**
		 * 授权登录
		 */
		wxAuth () {
			let baseURL = this.$store.state.baseURL
			window.location.href = baseURL + '/front/auth?url=' + window.location.href
		},
  },
  components: {
  }
}
</script>


<style lang="scss">
:root {
  --color-1: #FF9CAF;
  --color-2: #D1BF9E;
  --color-3: #A5ECDC;
}
#app {
  font-family: "Source Han Sans CN","Droid Sans",'Tahoma','Arial','Helvetica','sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
	padding-bottom: 36px;
}
*{
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: top;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
img{
  display: block;
  pointer-events: none;
}
button {
  margin: 0;
  padding: 0;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  width: max-content;
  border:  none;
  display: block;
}
ul{
  list-style: none;
}
html{
	width: 100%;
	height: 100%;
	background: #000;
	// background: url('../src/assets/img/bg.png') top center repeat-y;
	// background-size: 100% 100%;
}
sup{
	font-size: 12px;
	margin-top: -4px;
	display: inline-block;
}
svg{
	height: auto;
	display: block;
}

.top{
	position: fixed;
	top: 20px;
	left: 30px;
	display: flex;
	align-items: center;
	z-index: 99;
	img{
		width: 100%;
	}
	.home{
		width: 56px;
	}
	.back{
		width: 86px;
		margin-left: 13px;
	}
}
.wrap{
	color: #fff;
}

.submit-tip{
	background-color: rgba(255, 255, 255, 1)!important;
	color: #000!important;
}
.md-wrap{
	position: relative;
	z-index: 2;
}
.copyright{
	width: 100%;
	color: #fff;
	display: flex;	
	align-items: center;
	justify-content: center;
	font-size: 20px;
	position: fixed;
	bottom: 20px;
	left: 0;
	z-index: 1;

	a{
		color: #fff;
	}

	img{
		width: 22px;
	}
}
</style>
