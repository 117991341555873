import Vue from "vue";
import axios from "axios";
import { Toast, Dialog } from "vant";
import "vant/lib/dialog/style";
import store from '../../store'
Vue.use(Toast);
Vue.use(Dialog);

let rootUrl = store.state.baseURL

const instance = axios.create({
    baseURL: rootUrl,
    timeout: 10000,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json"
    },
});

// 请求拦截
instance.interceptors.request.use(
    (config) => {
        // console.log(config)
        return config;
    },
    (error) => {
        return Promise.resolve(error);
    }
);

// 响应拦截
instance.interceptors.response.use(
    (response) => {
        // console.log(response)
        if (response.data.code !== 200) {
            setTimeout(function() {
                Toast({
                    message: response.data.msg,
                    duration: 3000,
                });
            }, 0);
        }
        return response.data;
    },
    (err) => {
        Toast.clear();
        // console.log(err.response)
        if (err.message.includes("404")) {
            // token失效
            setTimeout(function() {
                Toast({
                    message: "接口不存在",
                    duration: 3000,
                });
            }, 0);
        } else if (err.message.includes("timeout")) {
            // 超时
            setTimeout(function() {
                Toast({
                    message: "请检查网络再重新连接",
                    duration: 3000,
                });
            }, 0);
        } else {
            setTimeout(function() {
                Toast(err.response.data.Message);
            }, 0);
        }
        return Promise.reject(err);
    }
);

let setHeaders = (headers) => {
    // 数组或单个对象
    if (Array.isArray(headers)) {
        headers.forEach((header) => {
            instance.defaults.headers.common[header] = header;
        });
    }
};

const api = {
    request(method, url, params, headers) {
        const opts = { method, url };
        if (method === "get") {
            opts.params = params;
        } else {
            opts.data = params;
        }
        if (headers) {
            setHeaders(headers);
        }
        return instance.request(opts);
    },
    get(url, params, headers) {
        return this.request("get", url, params || {}, headers);
    },
    post(url, params, headers) {
        return this.request("post", url, params || {}, headers);
    },
    delete(url, params, headers) {
        return this.request("delete", url, params || {}, headers);
    },
    put(url, params, headers) {
        return this.request("put", url, params || {}, headers);
    },
    patch(url, params, headers) {
        return this.request("patch", url, params || {}, headers);
    },
};

export default api;