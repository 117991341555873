import http from "../http";

// 病因诊断分类（全部分类）接口
export function getDiagnosis(param) {
	return http.get("/api/get-cause-diagnosis", param || {});
}

// 病因诊断分类（按级分类）接口
export function getDiagnosisList(param) {
	return http.post("/api/get-cause-diagnosis-list", param || {});
}

// 病因诊断详情接口
export function getDiagnosisDetail(param) {
	return http.post("/api/get-cause-diagnosis-detail", param || {});
}

// 评估工具分类（按级分类）接口
export function getAssessTool(param) {
	return http.post("/api/get-assess-tool", param || {});
}

// 评估工具详情接口
export function getAssessToolDetail(param) {
	return http.post("/api/get-assess-tool-detail", param || {});
}

// 问卷列表接口（自量表）
export function getQuestionnaireList(param) {
	return http.post("/api/get-questionnaire-list", param || {});
}

// 问卷题目详情接口
export function getQuestionList(param) {
	return http.post("/api/get-question-list", param || {});
}

// 问卷题目接口（评估工具）
export function getToolQuestionList(param) {
	return http.post("/api/get-tool-question-list", param || {});
}

// 页面访问统计
export function submitVisitInfo(param) {
	return http.post("/api/submit-visit-info", param || {});
}

// 病因诊断病因元素内容接口
export function getFactorContent(param) {
	return http.post("/api/get-cause-factor-content", param || {});
}

// 获取当前用户openid接口
export function getOpenid (param) {
  return http.post('/api/get-openid', param || {})
}

// hcp用户验证接口
export function authorize (param) {
  return http.get('/front/auth2/authorize', param || {})
}

// 获取慢咳之路列表接口
export function getRoadList (param) {
  return http.post('/api/get-slow-cough-list', param || {})
}

// 获取慢咳之路详情接口
export function getRoadDetail (param) {
  return http.post('/api/get-slow-cough-detail', param || {})
}

// 删除opendid
export function delOpenid (param) {
  return http.post('/api/clear', param || {})
}

// 同意/取消用户协议
export function userConsent (param) {
  return http.post('/api/user-consent', param || {})
}

// 当月总访问量
export function getMonthViews (param) {
  return http.post('/api/get-month-views', param || {})
}
