import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import './common/record.js'
import './common/shareReady.js'
import { getOpenid } from './common/api/index'

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
	if (to.path == '/disease') {
		from.meta.isUseCache = true;    
	}

	// console.log('main------'+store.state.openid)
	// console.log(from.path)
	// console.log(to.path)
	if (store.state.openid) {
		next()
	} else {
		// 'is_debug': 1
		getOpenid({}).then(res => {
			// console.log(res.data)
			if (!res.data.openid && !res.data.consent && to.path != '/' && to.path != '/clear') {
				next('/')
			} else {
				store.commit('change', res.data.openid)
				store.commit('changeConsent', res.data.consent)
				next()
			}
		})
	}
})
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");