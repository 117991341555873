/* eslint-disable */
import Vue from 'vue'
import { submitVisitInfo } from './api/index'
const common = {
  _record (param) {
		// console.log(param)
		submitVisitInfo(param).then(res => {
			// console.log(res)
		})
	}
}
export default common
Vue.prototype.$Common = common
