import Vue from 'vue'
import axios from "axios"
import wx from 'weixin-js-sdk'

axios.get('https://ebook.msdcnmrl.cn/msdebook/wxmp/common/msdebook/getShareData', {
	params: {
		userToken: '2e6780f938e747993b196f5360c6b4d7',//请求参数
		url: encodeURIComponent(location.href)
	},
}).then((res) => {
	wx.config({
		debug: false, // 开启调试模式
		appId: res.data.appId, // 必填，公众号的唯一标识
		timestamp: res.data.timestamp, // 必填，生成签名的时间戳
		nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
		signature: res.data.signature, // 必填，签名
		jsApiList: [
			'updateTimelineShareData',
			'onMenuShareTimeline',
			'onMenuShareAppMessage',
			'updateAppMessageShareData'
		], // 必填，需要使用的JS接口列表
		openTagList: []
	});
})

const wxShare = {
  _wxConfigJSSDK () {
    wx.ready(function () {
      // 即将废弃
      wx.onMenuShareTimeline({
        title: '慢咳查询小工具',
				link: 'https://curemap.msdcnmrl.cn/?t=' + Date.now(),
				imgUrl: 'https://curemap.msdcnmrl.cn/images/shareimg.jpg',
				success: function () {
          console.log('success')
        }
      })

      wx.onMenuShareAppMessage({
        title: '慢咳查询小工具',
				desc: '“咳”不容缓，求索无限，助力开启临床无咳新征程！',
				link: 'https://curemap.msdcnmrl.cn/?t=' + Date.now(),
				imgUrl: 'https://curemap.msdcnmrl.cn/images/shareimg.jpg',
        success: function () {
          console.log('success')
        }
      })

      wx.updateAppMessageShareData({
        title: '慢咳查询小工具',
				desc: '“咳”不容缓，求索无限，助力开启临床无咳新征程！',
				link: 'https://curemap.msdcnmrl.cn/?t=' + Date.now(),
				imgUrl: 'https://curemap.msdcnmrl.cn/images/shareimg.jpg',
        success: function () {
          console.log('success')
        }
      })

      wx.updateTimelineShareData({
        title: '慢咳查询小工具',
				link: 'https://curemap.msdcnmrl.cn/?t=' + Date.now(),
				imgUrl: 'https://curemap.msdcnmrl.cn/images/shareimg.jpg',
        success: function () {
          console.log('success')
        }
      })

      wx.showOptionMenu()
    })

    wx.error(function (res) {
      console.log('error', res)
    })
  },
  _wxHideMenu () {
    wx.ready(function () {
      wx.hideOptionMenu()
    })
    wx.error(function () {
      // alert("出错了：" + res.errMsg)
    })
  }
}

export default wxShare
Vue.prototype.$wxShare = wxShare
