import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "Home",
		meta: {
			name: '首页'
		},
		component: () => import ('../views/home.vue')
	},
	{
		path: "/brief",
		name: "Brief",
		meta: {
			name: '前言简介'
		},
		component: () => import ('../views/brief.vue')
	},
	{
		path: "/diacrisis",
		name: "Diacrisis",
		meta: {
			name: '病因诊断',
			keepAlive: true,
			isUseCache: false
		},
		component: () => import ('../views/diacrisis.vue')
	},
	{
		path: "/disease",
		name: "Disease",
		meta: {
			name: '疾病详情'
		},
		component: () => import ('../views/disease.vue')
	},
	{
		path: "/info",
		name: "Info",
		meta: {
			name: '问卷'
		},
		component: () => import ('../views/info.vue')
	},
	{
		path: "/tools",
		name: "Tools",
		meta: {
			name: '评估工具'
		},
		component: () => import ('../views/tools/tools.vue')
	},
	{
		path: "/toolsList",
		name: "ToolsList",
		meta: {
			name: '评估工具'
		},
		component: () => import ('../views/tools/list.vue')
	},
	{
		path: "/toolsInfo",
		name: "toolsInfo",
		meta: {
			name: ''
		},
		component: () => import ('../views/tools/info.vue')
	},
	{
		path: "/toolsQA",
		name: "toolsQA",
		meta: {
			name: ''
		},
		component: () => import ('../views/tools/question.vue')
	},
	{
		path: "/gauge",
		name: "Gauge",
		meta: {
			name: '量表'
		},
		component: () => import ('../views/gauge.vue')
	},
	{
		path: "/reference",
		name: "Reference",
		meta: {
			name: '参考文献'
		},
		component: () => import ('../views/reference.vue')
	},
	{
		path: "/road",
		name: "Road",
		meta: {
			name: '慢咳之路'
		},
		component: () => import ('../views/road/road.vue')
	},
	{
		path: "/roadDetail",
		name: "RoadDetail",
		meta: {
			name: '慢咳之路'
		},
		component: () => import ('../views/road/detail.vue')
	},
	{
		path: "/agreement",
		name: "agreement",
		meta: {
			name: '隐私声明'
		},
		component: () => import ('../views/agreement.vue')
	},
	{
		path: "/disclaimer",
		name: "disclaimer",
		meta: {
			name: '免责声明'
		},
		component: () => import ('../views/disclaimer.vue')
	},
	{
		path: "/clear",
		name: "clear",
		meta: {
			name: '清除收藏指引缓存'
		},
		component: () => import ('../views/clear.vue')
	}
];
// console.log(process.env.BASE_URL)
// console.log(process)
const router = new VueRouter({
	mode: "history",
	// base: '/dev-test-manke/',
	routes,

	//跳转的时候滚动条回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
});

export default router;